<template>
  <validation-observer ref="observer" v-slot="{ passes }">
    <v-form @submit.prevent="passes(submitForm)">
      <validation-provider v-slot="{ errors }" name="username" rules="required">
        <v-text-field
          v-model="form.username"
          :label="$t('fields.username')"
          :prepend-icon="icons.mdiAccount"
          :error-messages="errors"
          counter="20"
          maxlength="20"
          outlined
          autocomplete="username"
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="password" rules="required">
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          v-model="form.password"
          :label="$t('fields.password')"
          :prepend-icon="icons.mdiLock"
          :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
          :error-messages="errors"
          maxlength="128"
          outlined
          autocomplete="current-password"
        />
      </validation-provider>
      <v-checkbox v-model="form.remember" :label="$t('wot.rememberMe')"></v-checkbox>
      <v-btn color="primary" type="submit" >{{ $t('signIn') }}</v-btn>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  mdiAccount,
  mdiLock,
  mdiEye,
  mdiEyeOff,
} from '@mdi/js';

export default {
  metaInfo() {
    return {
      title: this.$t('login'),
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      username: '',
      password: '',
      remember: false,
    },
    showPassword: false,
    icons: {
      mdiAccount,
      mdiLock,
      mdiEye,
      mdiEyeOff,
    },
  }),
  computed: {
    ...mapGetters('auth', ['authErrors']),
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapMutations(['setLoading']),
    async submitForm() {
      this.setLoading(true);

      try {
        await this.signIn(this.form);

        this.$router.replace({ name: 'home' });
      } catch (e) {
        console.log(e);
      }

      this.setLoading(false);
    },
  },
  watch: {
    authErrors() {
      this.$refs.observer.setErrors(this.authErrors);
    },
  },
};
</script>
