<template>
  <v-img
    :src="require(`@/assets/${fileName}`)"
    :lazy-src="require(`@/assets/${fileName}?lazy`)"
    :height="height"
    :width="width"
  >
    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="info"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: undefined,
    },
    width: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
};
</script>
