<template>
  <v-row class="main-row">
    <v-col md="10" offset-md="1" lg="4" offset-lg="4" align-self="center">
      <v-card class="pa-5 light-green lighten-1" elevation="24">
        <v-row>
          <v-col cols="8">
            <wot-lazy-image :fileName="'logo.png'"/>
          </v-col>
        </v-row>
        <v-card-text>
          <login-form/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import WotLazyImage from '@/components/wot/WotLazyImage.vue';
import LoginForm from '@/components/forms/LoginForm.vue';

export default {
  components: {
    WotLazyImage,
    LoginForm,
  },
};
</script>

<style scoped>
.main-row {
  height: 90vh;
}
</style>
